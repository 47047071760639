export default {
  'loyalty.overview.title': 'Welcome back. Here’s your business at a glance.',

  'loyalty.range.custom': 'Custom',
  'loyalty.range.today': 'Today',
  'loyalty.range.yesterday': 'Yesterday',
  'loyalty.range.7d': '7D',
  'loyalty.range.30d': '30D',
  'loyalty.range.3m': '3M',
  'loyalty.range.12m': '12M',

  'loyalty.summary.custom': 'Custom',
  'loyalty.summary.today': 'Today',
  'loyalty.summary.yesterday': 'Yesterday',
  'loyalty.summary.7d': 'Last 7 days',
  'loyalty.summary.30d': 'Last 30 days',
  'loyalty.summary.3m': 'Last 3 months',
  'loyalty.summary.12m': 'Last 12 months',

  // 'loyalty.chart.total_members': 'Total Members',
  'loyalty.chart.total_members': 'Total Members by signup',
  'loyalty.chart.total_members.alias': 'Total Members by signup',
  'loyalty.chart.total_members_by_purchase.alias': 'Total members by last purchase',
  'loyalty.chart.total_members_by_expiration.alias': 'Total members by expiration',
  'loyalty.chart.total_members_by_purchase': 'Total members by last purchase',
  'loyalty.chart.total_members_by_expiration': 'Total members by expiration',
  'loyalty.chart.total_visits': 'Total Visits',
  'loyalty.chart.total_cashback': 'Total Cashback Issued',
  'loyalty.chart.total_prepaidcard': 'Number of Members with Prepaid Card Purchase | Value',
  'loyalty.chart.total_prepaidcard_title': '{people} Members | {amount}',
  'loyalty.chart.total_sign_up': 'Total Sign-ups',
  'loyalty.chart.member_trends': 'Member Trends',
  'loyalty.chart.visits_trends': 'Visits Trends',
  'loyalty.chart.cashback_trends': 'Cashback Trends',
  'loyalty.chart.signup_source_trends': 'Sign Up Source Trends',
  'loyalty.chart.autopilot': 'Autopilot',

  'loyalty.percent.total_members': 'of the {total} total members',
  'loyalty.percent.total_visits': 'of the {total} total visits',
  'loyalty.percent.total_cashback': 'of the {total} total cashback issued',
  'loyalty.percent.total_sign_up': 'of the {total} total sign-ups',
  'loyalty.percent.total_prepaidcard': 'of the {total} total prepaidcard purcased',

  'loyalty.legend.pending': 'Pending',
  'loyalty.legend.new': 'New',
  'loyalty.legend.regular': 'Regular',
  'loyalty.legend.lowFrequency': 'Low-frequency',
  'loyalty.legend.low_frequency': 'Low-frequency',
  'loyalty.legend.atRisk': 'At-risk',
  'loyalty.legend.at_risk': 'At-risk',
  'loyalty.legend.lost': 'Inactive',
  'loyalty.legend.recent': 'Recent',
  'loyalty.legend.inactive': 'Inactive',
  'loyalty.legend.redeemed': 'Redeemed',
  'loyalty.legend.unredeemed': 'Unredeemed',
  'loyalty.legend.issued': 'Issued',
  'loyalty.legend.return_visits': 'Return visits',
  'loyalty.legend.new_visits': 'New visits',
  'loyalty.legend.in_store': 'In store',
  'loyalty.legend.qr_code': 'QR code ordering',
  'loyalty.legend.smart_ordering': 'Smart ordering',
  'loyalty.legend.qr_code_ordering': 'Smart ordering',
  'loyalty.legend.kiosk': 'Kiosk',
  'loyalty.legend.prepaidcard_redeemed': 'Redeemed',
  'loyalty.legend.prepaidcard_unredeemed': 'Unredeemed',
  'loyalty.legend.count': 'Send',
  'loyalty.legend.pos': 'POS APP',
  'loyalty.legend.loyalty_web': 'Loyalty Web',
  'loyalty.legend.active': 'Active',

  'loyalty.legend.purchase_regular': 'Regular',
  'loyalty.legend.purchase_low_frequency': 'Low-frequency',
  'loyalty.legend.purchase_at_risk': 'At-risk',
  'loyalty.legend.purchase_lost': 'Lost',

  'loyalty.legend.expired.tota;': 'Expired',
  'loyalty.legend.expired_7_days': 'Expired 0～7 days',
  'loyalty.legend.expired_8_to_30_days': 'Expired 8～30  days',
  'loyalty.legend.expired_31_to_90_days': 'Expired 31～90 days',
  'loyalty.legend.expired_90_days': 'Expired 90～',
  'loyalty.legend.unexpired': 'Unexpired',

  'loyalty.legend.sms': 'SMS',
  'loyalty.legend.mms': 'MMS',

  'loyalty.definition.purchase_regular': 'Members whose last order is within 30 days',
  'loyalty.definition.purchase_low_frequency': 'Members whose last order is within 31～90 days',
  'loyalty.definition.purchase_at_risk': 'Members whose last order is within 91～180 days',
  'loyalty.definition.purchase_lost': 'Members whose last order is over 180 days',
  'loyalty.percent.total_members_by_purchase': 'of the {total} total member by last purchased',

  'loyalty.definition.expired_7_days': 'Members who expire 0～7 days',
  'loyalty.definition.expired_8_to_30_days': 'Members who expire 8～30  days',
  'loyalty.definition.expired_31_to_90_days': 'Mmembers who expire 31～90 days',
  'loyalty.definition.expired_90_days': 'Members who expire 90～ days',
  'loyalty.definition.unexpired': 'Members with validation',
  'loyalty.percent.total_members_by_expiration': 'of the {total} total member by expiration',

  'loyalty.definition.new': 'Members who registered within 7 days',
  'loyalty.definition.recent': 'Members who registered between 8 and 30 days',
  // 'loyalty.definition.new': 'Members who registered within {days} days',
  // 'loyalty.definition.recent': 'Members who registered within {days} days',
  'loyalty.definition.regular': 'Members who have ordered from your restaurant within {days} days',
  'loyalty.definition.lowFrequency': 'Members who have ordered from your restaurant within {days} days',
  'loyalty.definition.atRisk': 'Members who have ordered from your restaurant within {days} days',
  'loyalty.definition.lost': 'Members who have ordered from your restaurant within {days} days',
  'loyalty.definition.inactive': 'Members who haven’t ordered from your restaurant within {days} days',
  'loyalty.definition.redeemed': 'Cashback issued and has been used',
  'loyalty.definition.unredeemed': 'Outstanding cashback that has not been used',
  'loyalty.definition.return_visits': 'Orders made by regular or inactive members',
  'loyalty.definition.new_visits': 'Orders made by new members',
  'loyalty.definition.in_store': 'Signed up through table signage in store',
  'loyalty.definition.qr_code': 'Signed up through QR code ordering website',
  'loyalty.definition.kiosk': 'Signed up through kiosk',
  'loyalty.definition.pos': 'Signed up through POS App',

  'loyalty.definition.prepaidcard_redeemed': 'PrepaidCard puchased and has been used',
  'loyalty.definition.prepaidcard_unredeemed': 'PrepaidCard value that has not been used',

  'loyalty.manualcampaign.title': 'Manual campaign',
  'loyalty.manualcampaign.action.createCampaign': 'Create campaign',
  'loyalty.manualcampaign.action.editCampaign': 'Edit',
  'loyalty.manualcampaign.action.duplicateCampaign': 'Duplicate',
  'loyalty.manualcampaign.createCampaign.title': 'New campaign',
  'loyalty.manualcampaign.editCampaign.title': 'Edit campaign',
  'loyalty.manualcampaign.action.cancel': 'Cancel',
  'loyalty.manualcampaign.action.save': 'Save',
  'loyalty.manualcampaign.campaignName.title': 'Campaign Name',
  'loyalty.manualcampaign.campaignForeignName.title': 'Campaign Foreign Name',
  'loyalty.manualcampaign.campaignDesc.title': 'Campaign Description',
  'loyalty.manualcampaign.campaignForeignDesc.title': 'Campaign Foreign Description',
  'loyalty.manualcampaign.campaignContent.en.title': 'Campaign Content - English Version',
  'loyalty.manualcampaign.campaignContent.zh.title': 'Campaign Content - Chinese Version',
  'loyalty.manualcampaign.target': 'Target User',
  'loyalty.manualcampaign.field.target.placeholder': 'Please select target',
  'loyalty.manualcampaign.target.estimated.title': 'Reachable Users estimated:',
  'loyalty.manualcampaign.target.expected.segments.title': 'Expected sending segments:',
  'loyalty.manualcampaign.target.remain.segments.title': 'Remaining available segments within package:',
  'loyalty.manualcampaign.target.package.exceed.tips':
    'remaining available segments within package0, the part exceeding package will be charged extra $0.02/segments',
  'loyalty.manualcampaign.target.estimatedUserTotal': 'Total:',
  'loyalty.manualcampaign.target.estimatedSMSTotal': 'Segments:',
  'loyalty.manualcampaign.charge.estimated.title': 'Charge estimated:',
  'loyalty.manualcampaign.charge.estimatedUserTotal': 'Total:',
  'loyalty.manualcampaign.charge.estimatedSMSTotal': 'Segments:',
  'loyalty.manualcampaign.sms.sent': 'Sent',
  'loyalty.crm.sms.segments': 'Segments',
  'loyalty.manualcampaign.target.expirdRange': 'Exipred Data Range',
  'loyalty.manualcampaign.field.expiredDateRangeStart.required': 'Required',
  'loyalty.manualcampaign.field.expiredDateRangeEnd.required': 'Required',
  'loyalty.manualcampaign.field.expiredDateRange.error': 'End must be more than Start',

  'loyalty.campaign.subscribed': 'Active',
  'loyalty.campaign.unsubscribed': 'Inactive',
  'loyalty.autocampaign.action.editCampaign': 'Edit',

  'loyalty.campaign.subscribed.desc': 'Status:Active',
  'loyalty.campaign.unsubscribed.desc': 'Status:Inactive',
  'loyalty.campaign.type.SMS.desc': 'Type:SMS',
  'loyalty.campaign.type.MMS.desc': 'Type:MMS',

  'loyalty.autopilotcampaign.editCampaign.title': 'Edit autopilot campaign',
  'loyalty.autopilotcampaign.action.cancel': 'Cancel',
  'loyalty.autopilotcampaign.action.save': 'Save',

  'loyalty.manualcampaign.scheduledDelivery.title': 'Delivery Time',
  'loyalty.manualcampaign.scheduledDelivery.placeholder': 'Please select delivery time',
  'loyalty.manualcampaign.scheduledDelivery.startTime': 'Start time',
  'loyalty.manualcampaign.scheduledDelivery.onDate': 'On date',
  'loyalty.manualcampaign.scheduledDelivery.expired': 'Scheduled Delivery time must be after now',
  'loyalty.manualcampaign.status.title': 'Campaign status',

  'loyalty.manualcampaign.field.name.placeholder': 'Please enter campaign name',
  'loyalty.manualcampaign.field.name.required': 'Please enter campaign name',
  'loyalty.manualcampaign.field.desc.placeholder': 'Please enter campaign description',
  'loyalty.manualcampaign.field.desc.required': 'Please enter campaign description',
  'loyalty.manualcampaign.field.content.required': 'Please enter text content',
  'loyalty.manualcampaign.field.sensitive': 'This copy contains sensitive words “word”',
  'loyalty.manualcampaign.field.scheduledDelivery.required': 'Please select delivery time',
  'loyalty.manualcampaign.field.content.placeholder': 'The text content users see in SMS/MMS messages',
  'loyalty.manualcampaign.field.content.addVariables': 'Add Variables',
  'loyalty.manualcampaign.field.content.length.tips': 'Within 150 characters for English and 65 characters for Chinese',
  'loyalty.manualcampaign.field.userSegment.required': 'Please select target user',
  'loyalty.manualcampaign.content.exceed':
    'The text content exceeded the limit: Within 150 characters for English and 65 characters for Chinese',
  'loyalty.manualcampaign.create.success': 'Create Campaign successfully',
  'loyalty.manualcampaign.create.failed': 'Create Campaign failed',
  'loyalty.manualcampaign.update.success': 'Update Campaign successfully',
  'loyalty.manualcampaign.update.failed': 'Update Campaign failed',
  'loyalty.autopilotcampaign.update.success': 'Update Autopilot Campaign successfully',
  'loyalty.autopilotcampaign.update.failed': 'Update Autopilot Campaign failed',
  'loyalty.campaign.field.content.length.tips': '{charCount} | {billableSegments} Billable Segments',
  'loyalty.campaign.content.segments.exceed': 'No more than 10 segments of messages',
  'loyalty.campaign.field.content.length.tips.MMS': '{charCount} | 1,560 characters',
  'loyalty.campaign.field.content.MMS.popover':
    'MMS messages contain multimedia assets and message body. The message body can contain up to 1,560 characters.',
  'loyalty.campaign.content.MMS.exceed': 'No more than 1,560 characters',

  'loyalty.autopolit.title': 'Autopilot',

  'loyalty.userSegment.new': 'New Register',
  'loyalty.userSegment.regular': 'Regular',
  'loyalty.userSegment.atRisk': 'At-risk',
  'loyalty.userSegment.lost': 'Lost',
  'loyalty.userSegment.inactive': 'Inactive',
  'loyalty.userSegment.all': 'All',

  'loyalty.chart.autopilot_new_register_trends': 'Autopilot New Register Trends',
  'loyalty.chart.autopilot_first_purchase_trends': 'Autopilot First Purchase Trends',
  'loyalty.chart.autopilot_repurchase_trends': 'Autopilot Re-purchase Trends',
  'loyalty.chart.autopilot_at_risk_trends': 'Autopilot At-risk Trends',
  'loyalty.chart.autopilot_lost_trends': 'Autopilot Lost Trends',
  'loyalty.chart.manual_campaign_trends': 'Manual Campaign',
  'loyalty.autocampaign.campaignSentInfo': 'Sent:{sent} Segments:{delivered}',
  'loyalty.autocampaign.show.last7day.sentInfo': 'Show last 7 day’s data',
  'loyalty.autocampaign.hide.last7day.sentInfo': 'Hide last 7 day’s data',

  'loyalty.legend.manual_camgaign_send': 'All',

  'loyalty.campaign.content.addPersonalization': 'Add Personalization',
  'loyalty.campaign.content.addPersonalization.confirm.button': 'Insert Personalization',
  'loyalty.campaign.content.addPersonalization.cancel.button': 'Cancel',

  'loyalty.segments.usage.title': 'CRM segments usage ({month})',
  'loyalty.segments.usage.used': 'Used:',
  'loyalty.segments.usage.actualUsed': 'Actual use:',
  'loyalty.segments.usage.monthlyCapacity': 'Monthly capacity:',

  'loyalty.campaign.SMS.usage.sent': 'SMS sent:{sms_sent}',
  'loyalty.campaign.SMS.usage.segments.sent': 'SMS segments:{sms_segments_sent}',

  'loyalty.campaign.MMS.usage.sent': 'MMS sent:{mms_sent}',
  'loyalty.campaign.MMS.usage.segments.sent': 'MMS segments:{mms_segments_sent}',

  'loyalty.points.settings.title': 'Loyalty Points',
  'loyalty.points.settings.accumulation.title': 'Accumulation',
  'loyalty.points.settings.accumulation.title.RG': 'Accumulation - RG',
  'loyalty.points.settings.accumulation.desc.consuming': 'Consuming Amount',
  'loyalty.points.settings.accumulation.desc.get': 'Get Points',
  'loyalty.points.settings.accumulation.desc.post': 'Points',
  'loyalty.points.settings.accumulation.summary':
    'Every ${consumingAmount} consumption accumulates {points} points, less than {consumingAmount}, no accumulation, default all consumption can get points',
  'loyalty.points.settings.redemption.title': 'Redemption',
  'loyalty.points.settings.accumulation.desc.consuming.isrequired': 'Consuming Amount is required',
  'loyalty.points.settings.accumulation.desc.get.isrequired': 'Get point is required',

  'loyalty.point.redemption.table.column.menuName': 'Menu',
  'loyalty.point.redemption.table.column.categoryName': 'Category',
  'loyalty.point.redemption.table.column.mealName': 'Dish',
  'loyalty.point.redemption.table.column.pointPrice': 'Point Price',

  'loyalty.points.report.title': 'Loyalty Points',
  'loyalty.point.report.summary.totalGrant': 'Total Grant Points',
  'loyalty.point.report.summary.totalRedeem': 'Total Redeem Points',
  'loyalty.point.report.RG.restaurantFilter': 'Filter by Restaurant',

  'pointsReport.column.order_number': 'Order#',
  'pointsReport.column.phone_number': 'Phone Number',
  'pointsReport.column.member_card_number': 'Card#',
  'pointsReport.column.name': 'Name',
  'pointsReport.column.product_names': 'Dish Name',
  'pointsReport.column.action_type': 'Consuming Type',
  'pointsReport.column.created_at': 'Issued',
  'pointsReport.column.points': 'Points Variation',
  'pointsReport.column.balance': 'Points Balance',
  'pointsReport.column.pointsSource': 'Source Restaurant',
  'loyalty.sms.segmetns.explain.content': `What is an SMS segment?
  The Short Messaging Service (SMS) is a standardized communication protocol that enables devices to send and receive brief text messages. It was designed to “fit in between” other signaling protocols, which is why SMS message length is limited to 160 7-bit characters, i.e., 1120 bits, or 140 bytes. SMS message segments are the character batches that phone carriers use to measure text messages. Messages are charged per message segment, so clients leveraging SMS greatly benefit from understanding the nuances of how messages will be split.
  As you create an SMS campaign , the messages you build are representative of what your users may see when the message gets delivered to their phone, but is not indicative of how your message will be split into segments and ultimately how you be charged. Understanding how many segments will be sent and being aware of the potential overages that could occur is your responsibility, but we provide some resources to make this easier for you.`,
  'loyalty.sms.segmetns.explain.reminder':
    "According to the operator's requirements, an unsubscribe reminder must be included in the message, and the reminder will also be counted in the SMS/MMS character statistics.",

  'loyalty.points.settings.accumulation.availability.meals.title': 'Accumulation Scope',
  'loyalty.points.settings.accumulation.scope.title': 'Applicable',
  'loyalty.points.settings.accumulation.scope.option.all': 'all available',
  'loyalty.points.settings.accumulation.scope.option.excluding': 'partial available',
  'loyalty.points.settings.accumulation.scope.excluding.category.title': 'category excluded',
  'loyalty.points.settings.accumulation.scope.excluding.category.subtitle': 'select category',
  'loyalty.points.settings.accumulation.scope.excluding.category.tips':
    '(if "category excluded" is selected,it will automatically be applied when items are added into this category after setting effective',
  'loyalty.points.settings.accumulation.scope.excluding.meal.title': 'item excluded',
  'loyalty.points.settings.accumulation.scope.excluding.meal.subtitle': 'Select Meal',
  'loyalty.points.settings.accumulation.scope.excluding.meal.tips': '',

  'loyalty.points.settings.accumulation.availability.save.success': 'Accumulation Scope is saved succesfully',
  'loyalty.points.settings.accumulation.availability.save.fail': 'Accumulation Scope is saving failed',

  'loyalty.points.settings.accumulation.scope.switch.confirm':
    'Are you confirm that Accumulation is valid for all categories and meals',
  'loyalty.points.settings.accumulation.scope.editor.selectCategory': 'Select category (not include point category):',
  'loyalty.points.settings.accumulation.scope.editor.selectedCategory': 'Selected categories:',
  'loyalty.points.settings.accumulation.scope.editor.clearAllSelectedCategories': 'Clear All',

  'loyalty.points.settings.accumulation.scope.editor.selectedMeal': 'Selected Meal:',
  'loyalty.points.settings.accumulation.scope.editor.clearAllSelectedMeals': 'Clear All',

  'loyalty.sensitive.sentence1':
    'SMS message segment is limited to 160 7-bit characters, i.e., 1120 bits, or 140 bytes. When the content length exceeds this limit, the phone carrier will divide the content into multiple message segments.',
  'loyalty.sensitive.sentence2':
    'MMS message contain multimedia assets and message body. The total file size limit is 600 KB, and the message body limit is within 1,560 characters. Phone carrier will charge fees based on the number of MMS messages.',
  'loyalty.sensitive.sentence3':
    'Phone carrier will not send text messages under the following circumstances: contains sensitive words, such as vape, heroin, drugs, cigarettes, etc.; the phone number has been blacklisted by the carrier.',
  'loyalty.sensitive.sentence4': `According to the operator's requirements, an unsubscribe reminder must be included in the message, and the reminder will also be counted in the SMS character statistics.`,

  'loyalty.campaign.form.card.setting.title': 'Settings',
  'loyalty.campaign.form.card.setting.status.field.title': 'Campaign Status',
  'loyalty.campaign.form.card.setting.type.field.title': 'Campaign Type',
  'loyalty.campaign.form.card.setting.type.option.SMS': 'SMS',
  'loyalty.campaign.form.card.setting.type.option.MMS': 'MMS',

  'loyalty.campaign.form.card.content.title': 'Content',
  'loyalty.campaign.SMS.content.reviewer.tips': `The display effect may vary on different phones; please refer to the actual sent results.
    According to the operator's requirements, an unsubscribe reminder must be included in the message, and the reminder will also be counted in the SMS/MMS character statistics.`,

  'loyalty.campaign.MMS.content.reviewer.tips': `We accept JEPG, GIF, PNG files that are less than 600KB per file.
    The display effect may vary on different phones; please refer to the actual sent results.
    According to the operator's requirements, an unsubscribe reminder must be included in the message, and the reminder will also be counted in the SMS/MMS character statistics.`,

  'loyalty.campaign.unsubscribe.tips.en': 'Reply T to unsubscribe',
  'loyalty.campaign.unsubscribe.tips.zh': '回复T退订',

  'loyalty.campaign.MMS.media.upload': 'Upload Image',

  'loyalty.campaign.tabs.title': 'Campaign',

  'loyalty.manualcampaign.table.column.name': 'Campaign Name',
  'loyalty.manualcampaign.table.column.segment_id': 'Target User',
  'loyalty.manualcampaign.table.column.document_status': 'Status',
  'loyalty.manualcampaign.table.column.campaign_type': 'Type',
  'loyalty.manualcampaign.table.column.message_type': 'Type',
  'loyalty.manualcampaign.table.column.delivery_at': 'Delivery Time',
  'loyalty.manualcampaign.table.column.sent_count': 'Sent Qty',
  'loyalty.manualcampaign.table.column.segments_count': 'Segments Qty',
  'loyalty.manualcampaign.table.column.operator': 'Action',

  'loyalty.campaign.type.SMS': 'SMS',
  'loyalty.campaign.type.MMS': 'MMS',
  'loyalty.manualcampaign.table.column.segments_count.tips':
    'SMS displays the number of message segments; MMS displays the number of MMS messages.',
  'loyalty.manualcampaign.table.column.sent_count.tips':
    'SMS displays the number of message segments; MMS displays the number of MMS messages.',
  'loyalty.manualcampaign.query.placeholder': 'Search by campaign name',
  'loyalty.manualcampaign.filter.search': 'Filter',
  'loyalty.manualcampaign.filter.action.clear': 'Clear',
  'loyalty.manualcampaign.filter.targetuser': 'Target User',
  'loyalty.manualcampaign.filter.status': 'Status',
  'loyalty.manualcampaign.filter.type': 'Type',

  'loyalty.manualcampaign.filter.targetuser.placeholder': 'Please select',
  'loyalty.manualcampaign.filter.status.placeholder': 'Please select',
  'loyalty.manualcampaign.filter.type.placeholder': 'Please select',
  'loyalty.campaign.service.agreement': 'Terms of Using CRM SMS&MMS ',
  'loyalty.campaign.confirm.services': 'Agree to the agreement',
  'loyalty.campaign.service.agreement.for.employee': 'Please confirm the service agreement before use',
  'loyalty.campaign.service.agreement.for.employee.content':
    'Please ask the restaurant owner to confirm the new service agreement. New MMS service has been added and the SMS service charging method has been optimized.',

  'loyalty.campaign.subscribe.checking': 'Checking CRM subscription',
  'loyalty.campaign.subscribe.confirming': 'Confirming new CRM Term',
  'loyalty.campaign.MMS.imageUploadGuide.sizeTooLarge': 'The image is limited to 600KB in size',
  'loyalty.campaign.MMS.imageUploadGuide.formatNotMatched': 'The format should be .jpg, .jpeg, .png, .gif',
  'loyalty.campaign.MMS.media.required': 'Please Upload Image',

  'loyalty.twilio.modal.title': 'CRM Message Number Application',
  'loyalty.twilio.modal.content.title':
    'Welcome to our SMS & MMS marketing service. To provide you with better SMS and MMS marketing solutions, please fill our the following information and review the term of service.',
  'loyalty.twilio.modal.point_contract': 'Point of Contract',
  'loyalty.twilio.modal.business_information': 'Business Information',
  'loyalty.twilio.modal.checkbox.content':
    'By using the Chowbus CRM system to send SMS and MMS, I acknowledge that we, as the merchant, are solely responsible for the content of sent messages. I will comply with all local laws and regulations regarding the content of messaging. I understand that Chowbus’ SMS/MMS service provider reserves the right to block access to the service in case of any violations and that Chowbus has no control and is not liable for such cases. I understand that the messaging service provider has a maximum send speed cap of 3 segments per second and certain recipients may not receive messages immediately after sending. The original Chowbus Restaurant Technology Agreement clauses also apply to this service.',
  'loyalty.twilio.modal.register.btn': 'Register',
  'loyalty.twilio.modal.cancel.btn': 'Cancel',
  'loyalty.twilio.modal.skip.btn': 'Skip for now',
  'loyalty.twilio.modal.applied.btn': 'Applied',
  'loyalty.twilio.modal.valid.not_allowed_chinese': 'Chinese are not allowed',
  'loyalty.twilio.modal.valid.only_number': 'Only numbers allowed',
  'loyalty.twilio.modal.valid.email': 'Please enter a valid email address!',

  'loyalty.twilio.modal.required.email': 'Please enter Email Address!',
  'loyalty.twilio.modal.required.phone': 'Please enter Phone Number!',
  'loyalty.twilio.modal.required.first_name': 'Please enter First Name!',
  'loyalty.twilio.modal.required.last_name': 'Please enter Last Name!',
  'loyalty.twilio.modal.required.business_name': 'Please enter Business Name!',
  'loyalty.twilio.modal.required.address_one': 'Please enter Street Address 1!',
  'loyalty.twilio.modal.required.city': 'Please enter City!',
  'loyalty.twilio.modal.required.zip_code': 'Please enter Zip Code!',
  'loyalty.twilio.modal.required.business_website': 'Please enter Business Website!',
  'loyalty.twilio.modal.required.state': 'Please select State!',
  'loyalty.twilio.modal.required.country': 'Please select Country!',

  'loyalty.twilio.account.my_account': 'My Account',
  'loyalty.twilio.account.table.message_number': 'Message Number',
  'loyalty.twilio.account.table.submitted_by': 'Submitted By',
  'loyalty.twilio.account.table.submission_time': 'Submission Time',
  'loyalty.twilio.account.table.email_address': 'Email Address',
  'loyalty.twilio.account.table.Status': 'Status',
  'loyalty.twilio.account.table.action': 'Action',
  'loyalty.twilio.application.rejected': 'Application Rejected!',
  'loyalty.twilio.application.rejected.later': 'Later',
  'loyalty.twilio.application.rejected.modify': 'Modify',
  'loyalty.twilio.application.wrong': 'Whoops! Something Wrong…',
  'loyalty.twilio.application.wrong.content': 'Please contact our customer support for help.',
  'loyalty.twilio.application.wrong.btn': 'Go Back',
  'loyalty.twilio.rejected.inform':
    'We regret to inform you that your pending application for CRM message number has been rejected. Please review and modify the information provided, then resubmit your application',
  'loyalty.twilio.skip.title': 'Would you like to skip this step fow now?',
  'loyalty.twilio.skip.content': 'You can complete your application at any time by visiting the Account page.',

  'loyalty.twilio.account.drawer.title': 'Status History',
  'loyalty.twilio.account.status.pending': 'Pending',
  'loyalty.twilio.account.status.activated': 'Activated',
  'loyalty.twilio.account.status.rejected': 'Reject',
  'loyalty.twilio.account.status.reseted': 'Reset',
};
